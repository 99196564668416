var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: _vm.classes,
      attrs: { type: "button", "aria-label": _vm.item.title },
      on: { click: _vm.onClick }
    },
    [
      _vm.isIcon
        ? _c(_vm.tabIconComponent, {
            tag: "Component",
            attrs: { "fill-color": _vm.tabIconFillColor }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        staticClass: "tab-bar-item__title",
        domProps: { textContent: _vm._s(_vm.item.title) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
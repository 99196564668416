var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-tab-bar" },
    [
      _c(
        "div",
        { staticClass: "vertical-tab-bar__items" },
        [
          _vm._l(_vm.items, function(item, index) {
            return _c("VerticalTabBarItem", {
              key: index,
              attrs: {
                index: index,
                item: item,
                "is-selected": index === _vm.selectedTabIndex && _vm.isExpanded
              },
              on: { select: _vm.onSelectTabBarItem }
            })
          }),
          _vm._v(" "),
          _c("Fab", {
            attrs: { floating: { bottom: "16px" }, theme: _vm.secondaryTheme },
            nativeOn: {
              click: function($event) {
                return _vm.onExpand.apply(null, arguments)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function(ref) {
                  var color = ref.color
                  return [
                    _c("IconArrowDouble", {
                      attrs: { "fill-color": color, state: _vm.expendStateIcon }
                    })
                  ]
                }
              }
            ])
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.isContentVisible
        ? _c("VerticalTabContent", {
            attrs: { item: _vm.items[_vm.selectedTabIndex] }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
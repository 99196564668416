var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "horizontal-tab-bar-items" },
    [
      _c(
        "HorizontalFixedScroll",
        {
          nativeOn: {
            scroll: function($event) {
              return _vm.onScroll.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "horizontal-tab-bar-items__container",
              attrs: { "data-cy": "tab-bar__items" }
            },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "HorizontalTabBarItemResizeObserver",
                {
                  key: index,
                  attrs: { index: index },
                  on: { "width-change": _vm.onWidthChange }
                },
                [_vm._t("item", null, { index: index, item: item })],
                2
              )
            }),
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("TabBarItemSlider", {
        attrs: {
          widths: _vm.itemWidths,
          "scroll-left": _vm.scrollLeft,
          "selected-index": _vm.selectedIndex
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-tab-bar-content", style: _vm.styles },
    [
      _c(
        "div",
        { staticClass: "vertical-tab-bar-content__container" },
        [
          _c(
            "KeepAlive",
            [
              _c(
                _vm.item.component,
                _vm._b({ tag: "Component" }, "Component", _vm.item.props, false)
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ClientOnly",
        [
          _c("Resizer", {
            attrs: { "parent-reference": _vm.$el, "min-width": _vm.minWidth },
            on: { "width-change": _vm.onWidthChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
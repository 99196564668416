var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "horizontal-routing-tab-bar" },
    [
      _vm.isTabVisible
        ? _c("HorizontalTabBarItems", {
            attrs: { items: _vm.items, "selected-index": _vm.selectedIndex },
            scopedSlots: _vm._u(
              [
                {
                  key: "item",
                  fn: function(ref) {
                    var index = ref.index
                    var item = ref.item
                    return [
                      _vm._t(
                        "item",
                        function() {
                          return [
                            _c("HorizontalRoutingTabBarItem", {
                              attrs: {
                                index: index,
                                item: item,
                                "selected-index": _vm.selectedIndex,
                                "change-values": _vm.changeValues[item.scope],
                                errors: _vm.errors[item.scope]
                              },
                              on: { select: _vm.onSelectTabBarItem }
                            })
                          ]
                        },
                        { index: index, item: item }
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.items.length
        ? _c(
            "HorizontalTabBarContent",
            [
              _vm._t(
                "content",
                function() {
                  return [
                    _c("NuxtChild", {
                      key: _vm.$route.name,
                      attrs: {
                        "keep-alive": "",
                        scope: _vm.items[_vm.selectedIndex].scope,
                        "change-values":
                          _vm.changeValues[_vm.items[_vm.selectedIndex].scope],
                        errors: _vm.errors[_vm.items[_vm.selectedIndex].scope]
                      }
                    })
                  ]
                },
                {
                  item: _vm.items[_vm.selectedIndex],
                  changeValues:
                    _vm.changeValues[_vm.items[_vm.selectedIndex].scope],
                  errors: _vm.errors[_vm.items[_vm.selectedIndex].scope]
                }
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
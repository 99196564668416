var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("button", {
    staticClass: "horizontal-tab-bar-item",
    attrs: { type: "button", "aria-label": _vm.item.title },
    domProps: { textContent: _vm._s(_vm.item.title) },
    on: { click: _vm.onSelect }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FeedbackProvider", {
    attrs: { errors: _vm.errors, "change-values": _vm.changeValues },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hasValueToSave = ref.hasValueToSave
          var hasSuccess = ref.hasSuccess
          var hasError = ref.hasError
          return [
            _c("TranslatableTitleProvider", {
              attrs: { title: _vm.item.title },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(ref) {
                      var translatableTitle = ref.translatableTitle
                      return [
                        _c(
                          "div",
                          {
                            class: _vm.classes,
                            attrs: { tabindex: "-1" },
                            on: { click: _vm.onLinkSelect }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(translatableTitle) +
                                "\n                    "
                            ),
                            hasError
                              ? _c("IconError", {
                                  attrs: {
                                    "view-box": "6 -6 12 36",
                                    "fill-color": _vm.redColor
                                  }
                                })
                              : hasSuccess
                              ? _c("IconMark", {
                                  attrs: { "fill-color": _vm.greenColor }
                                })
                              : hasValueToSave
                              ? _c("IconSync", {
                                  attrs: { "fill-color": _vm.graphiteDarkColor }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
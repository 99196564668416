var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ResizeObserver", { on: { resize: _vm.onResize } }, [
    _c(
      "div",
      { staticClass: "horizontal-tab-bar-item-resize-observer" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }